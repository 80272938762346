<template>
  <!--底部-->
  <div class="footer d_jump" index="06" id="Footer"
       style=""
       :style='{
          background: "url(\""+cdnUrl+"/platform/static/footer.png\") no-repeat"
       }'>
    <!--      <img src="../assets/images/footer.png" style="width: 100%;height: auto">-->
    <div style="width: 1200px;margin-left: auto;margin-right: auto;letter-spacing: 4px">
      <div>
        <div style="padding-top: 44px;padding-bottom: 14px;font-size: 18px;font-weight: bold;line-height: 24px;color: #FFFFFF;">关于我们</div>
        <div class="list-item">联系方式：010-87772213</div>
        <div class="list-item">联系邮箱：gonature@hanyastar.com</div>
        <div class="list-item">公司地址：北京市朝阳区西大望路甲22号院1号楼商业部分4层03号</div>
        <div class="list-item">工作时间：周一至周五（9:00-18:00）</div>
        <div style="margin-top: 36px;line-height: 19px;font-size: 14px;">
          <a style="padding-right: 40px;color: #fff;text-decoration: none;" target="_blank" rel="noopener" href="http://beian.miit.gov.cn/">京ICP备15020968号-9</a>
          <a style="color: #fff;text-decoration: none;" target="_blank" rel="noopener" href="http://beian.miit.gov.cn/"><img src="../assets/images/beian.png" style="height: 20px;width: 20px;margin-right: 10px;vertical-align: bottom;cursor: pointer;"/>京公网安备 11010502045300号</a>
          <!-- <span @click="openWeb('https://beian.miit.gov.cn/#/Integrated/index')" style="padding-right: 40px;cursor: pointer;">京ICP备15020968号-9</span> -->
          <!-- <span style="cursor: pointer;" @click="openWeb('http://www.beian.gov.cn/portal/index.do')" v-show="true"><img src="../assets/images/beian.png" style="height: 20px;width: 20px;margin-right: 10px;vertical-align: bottom;cursor: pointer;"/>京公网安备 11010502045300号</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      cdnUrl: this.$store.state.cdnUrl
    }
  },
  methods: {
    openWeb (url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped>

</style>
<style scoped lang="less">
/*http://cdn.nature.hanyastar.cn/platform/static/footer.png*/
@import "../assets/css/home";
</style>
