import http from '../utils/http'
//
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */

// 发送验证码
export function sendVCode (params) {
  return http.post('/client_api/send_verifycode', params)
}
// 校验验证码
export function checkVCode (params) {
  return http.post('/client_api/check_verifycode', params)
}
// 上传文件接口--未登录
export function uploadKey (params) {
  return http.post('/client_api/get_upload_key_for_register', params)
}
// 第一步，先存储学校信息
export function createSchool (params) {
  return http.post('/client_api/school/create', params)
}

// 第二步，注册学校管理员用户
export function createUser (params) {
  return http.post('/client_api/user/register', params)
}
export function applactionForm (params) {
  return http.post('/client_api/registration/create', params)
}
