/** **   http.js   ****/
// 导入封装好的axios实例
import request from './request'

const http = {
  /**
   * methods: 请求
   * @param url 请求地址
   * @param params 请求参数
   */
  post (url, params) {
    const config = {
      method: 'post',
      url: process.env.VUE_APP_URL + url
    }
    if (params) config.data = params
    return request(config)
  }
}
// 导出
export default http
